















































import { Vue, Provide, Component } from "vue-property-decorator";
import { Toast } from "vant";
import area from "@/utils/area";
import serve from "@/serve/mine";
import Nav from "@/components/header.vue";
import MD5 from "js-md5";
interface File {
  status: string;
  message: string;
}
@Component({
  components: {
    Nav,
  },
})
export default class EditPsw extends Vue {
  newpassword2 = "";
  @Provide() mobile = "";
  @Provide() code = "";
  @Provide() sendLoading = false;
  @Provide() time = 60;
  @Provide() password = "";
  @Provide() newpassword = "";
  @Provide() show = false;
  @Provide() areaList = area; // 省市区数据
  @Provide() address = ""; // 区域
  @Provide() name = ""; // 公司名称
  @Provide() QQ = ""; // qq  agree
  @Provide() fileList = []; // 营业执照
  @Provide() agree = false; // 同意协议

  private validator(val: string): boolean {
    // 验证密码
    return val.length > 5 && val.length < 16;
  }
  private signInSubmit(): void {
    // this.$router.push("/signInSuccess");
    this.show = false;
  }
  async editPassword() {
    if (this.newpassword != this.newpassword2) {
      Toast.fail("两次密码不一致");
    } else {
      const data = {
        newPassword: MD5(this.newpassword),
        password: MD5(this.password),
      };
      const res = await serve.editPassword(data);
      if (res.data.code === 0) {
        Toast.success("修改成功");
        this.password = "";
        this.newpassword = "";
        this.newpassword2 = "";
      } else {
        Toast.fail(res.data.message);
      }
    }
  }
  private define (): void{
    this.show = false
  }
  private goBack(): void {
    this.$router.push("/login");
  }
}
